import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { PrimeButtonComponent, PrimeHtmlEditorComponent } from '@pinnakl/shared/ui/prime';
import { InlineSVGModule } from '@pinnakl/shared/util-providers';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

export interface DisclaimerModalData {
  logoUrl?: string;
  disclaimer: string;
  clientName: string;
  showBackButton: boolean;
}

@Component({
  standalone: true,
  selector: 'portal-disclaimer-modal',
  templateUrl: './disclaimer-modal.component.html',
  styleUrls: ['./disclaimer-modal.component.scss'],
  imports: [
    PrimeButtonComponent,
    NgClass,
    InlineSVGModule,
    PrimeHtmlEditorComponent,
    ReactiveFormsModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisclaimerModalComponent implements OnInit {
  private readonly dialogRef = inject(DynamicDialogRef);
  protected readonly dialogConfig: DynamicDialogConfig<DisclaimerModalData> =
    inject(DynamicDialogConfig);
  protected readonly disclaimerControl = new FormControl('', { nonNullable: true });

  ngOnInit(): void {
    this.disclaimerControl.setValue(this.dialogConfig.data?.disclaimer ?? '');
  }

  back(): void {
    this.dialogRef.close(false);
  }

  acceptAndContinue(): void {
    this.dialogRef.close(true);
  }
}
