<div class="flex flex-row align-items-start justify-content-between gap-2">
  <p-editor
    class="flex-grow-1"
    (onInit)="init($event)"
    (onSelectionChange)="onSelectionChange.emit($event)"
    (onTextChange)="onTextChange.emit($event)"
    [bounds]="bounds"
    [formControl]="$any(formControl)"
    [formats]="formats"
    [modules]="modules"
    [ngClass]="{ previewMode: previewMode }"
    [placeholder]="previewMode ? '' : placeholder"
    [scrollingContainer]="scrollingContainer"
    [styleClass]="styleClass"
    [style]="style"
  >
    <ng-template pTemplate="header">
      @if (!previewMode) {
        <span class="ql-formats">
          <select class="ql-header">
            <option selected="selected"></option>
            <option value="1"></option>
            <option value="2"></option>
            <option value="3"></option>
            <option value="4"></option>
            <option value="5"></option>
          </select>
          <button class="ql-bold"></button>
          <button class="ql-italic"></button>
          <button class="ql-underline"></button>
          <button class="ql-strike"></button>
          <select class="ql-color"></select>
          <select class="ql-background"></select>
          <button
            class="ql-list"
            value="ordered"
          ></button>
          <button
            class="ql-list"
            value="bullet"
          ></button>
          <select class="ql-align">
            <option selected="selected"></option>
            <option value="center"></option>
            <option value="right"></option>
            <option value="justify"></option>
          </select>
          <button class="ql-link"></button>
        </span>
        <span class="ql-formats">
          <button class="ql-clean"></button>
        </span>
      }
    </ng-template>
  </p-editor>
  @if (showCopyBtn) {
    <prime-button
      (onClick)="copyContent()"
      [text]="true"
      severity="secondary"
      size="sm"
      styleClass="py-1 px-2"
    >
      <ng-template #iconTemplate>
        <i
          [inlineSVG]="'sprites.svg#copy'"
          class="flex h-1rem w-1rem"
        ></i>
      </ng-template>
    </prime-button>
  }
</div>
