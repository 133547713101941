import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  inject,
  Input
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfoUIToastMessageCategory } from '@pinnakl/shared/types';
import { InlineSVGModule, PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { ClipboardService } from 'ngx-clipboard';
import { Attributor } from 'parchment';
import { EditorInitEvent, EditorModule } from 'primeng/editor';
import Quill from 'quill';
import { NoopValueAccessorDirective } from '../../directives';
import { PrimeButtonComponent } from '../button/prime-button.component';
import { ControlInjectedHtmlEditorComponent } from './control-injected-html-editor.component';
// https://stackoverflow.com/questions/59676897/inline-style-instead-of-quill-editor-inbuilt-classes-as-classes-are-not-availabl/75559027#75559027
const ColorClass = Quill.import('attributors/class/color') as Attributor;
const ColorStyle = Quill.import('attributors/style/color') as Attributor;
const BgClass = Quill.import('attributors/class/background') as Attributor;
const BgStyle = Quill.import('attributors/style/background') as Attributor;
const AlignClass = Quill.import('attributors/class/align') as Attributor;
const AlignStyle = Quill.import('attributors/style/align') as Attributor;
const SizeClass = Quill.import('attributors/class/size') as Attributor;
const SizeStyle = Quill.import('attributors/style/size') as Attributor;
Quill.register(ColorClass, true);
Quill.register(ColorStyle, true);
Quill.register(BgClass, true);
Quill.register(BgStyle, true);
Quill.register(AlignClass, true);
Quill.register(AlignStyle, true);
Quill.register(SizeClass, true);
Quill.register(SizeStyle, true);

@Component({
  imports: [
    CommonModule,
    EditorModule,
    FormsModule,
    ReactiveFormsModule,
    PrimeButtonComponent,
    InlineSVGModule
  ],
  standalone: true,
  selector: 'prime-html-editor',
  templateUrl: './prime-html-editor.component.html',
  styleUrls: ['./prime-html-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [NoopValueAccessorDirective]
})
export class PrimeHtmlEditorComponent extends ControlInjectedHtmlEditorComponent {
  private readonly clipboardService: ClipboardService = inject(ClipboardService);
  private readonly toast = inject(PinnaklUIToastMessage);
  @Input() previewMode = false;
  @Input() showCopyBtn = false;
  @Input() stopPropagationOnClick = true;

  constructor(cdr: ChangeDetectorRef) {
    super(cdr);
    this.placeholder = this.placeholder ?? 'Enter text...';
    this.bounds = 'body';
  }

  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent): void {
    if (this.stopPropagationOnClick) {
      event.stopPropagation();
    }
  }

  init(event: EditorInitEvent): void {
    this.quill = event.editor;
    this.setValue(this.formControl?.value);
    this.onInit.emit(event);
    if (this.previewMode) {
      this.quill.disable();
      this.formControl?.valueChanges
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(value => this.setValue(value));
    }
  }

  copyContent(): void {
    const text = this.quill?.getText();
    if (!text) return;
    this.clipboardService.copyFromContent(text);
    this.toast.info(`Content copied to clipboard`, InfoUIToastMessageCategory.Info, {
      sticky: false,
      life: 3000,
      summary: undefined
    });
  }

  private setValue(value): void {
    value && this.quill?.setText('', 'api');
    value && this.quill?.clipboard?.dangerouslyPasteHTML(0, value, 'api');
  }
}
