import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EditorInitEvent, EditorSelectionChangeEvent, EditorTextChangeEvent } from 'primeng/editor';

@Component({ template: '', standalone: true })
export class HtmlEditorPublicApiComponent {
  _readonly = false;
  quill: any;
  /**
   * Inline style of the container.
   * @group Props
   */
  @Input() style: { [klass: string]: any } | null | undefined;
  /**
   * Style class of the container.
   * @group Props
   */
  @Input() styleClass: string | undefined;
  /**
   * Placeholder text to show when editor is empty.
   * @group Props
   */
  @Input() placeholder: string | undefined;
  /**
   * Whitelist of formats to display, see here for available options.
   * @group Props
   */
  @Input() formats: string[] | undefined;
  /**
   * Modules configuration of Editor, see here for available options.
   * @group Props
   */
  @Input() modules: object | undefined;
  /**
   * DOM Element or a CSS selector for a DOM Element, within which the editor’s p elements (i.e. tooltips, etc.) should be confined. Currently, it only considers left and right boundaries.
   * @group Props
   */
  @Input() bounds: HTMLElement | string | undefined;
  /**
   * DOM Element or a CSS selector for a DOM Element, specifying which container has the scrollbars (i.e. overflow-y: auto), if is has been changed from the default ql-editor with custom CSS. Necessary to fix scroll jumping bugs when Quill is set to auto grow its height, and another ancestor container is responsible from the scrolling..
   * @group Props
   */
  @Input() scrollingContainer: HTMLElement | string | undefined;
  /**
   * Shortcut for debug. Note debug is a static method and will affect other instances of Quill editors on the page. Only warning and error messages are enabled by default.
   * @group Props
   */
  @Input() debug: string | undefined;
  /**
   * Whether to instantiate the editor to read-only mode.
   * @group Props
   */
  @Input() get readonly(): boolean {
    return this._readonly;
  }
  set readonly(val: boolean) {
    this._readonly = val;

    if (this.quill) {
      if (this._readonly) this.quill.disable();
      else this.quill.enable();
    }
  }
  /**
   * Callback to invoke when the quill modules are loaded.
   * @param {EditorInitEvent} event - custom event.
   * @group Emits
   */
  @Output() onInit: EventEmitter<EditorInitEvent> = new EventEmitter<EditorInitEvent>();
  /**
   * Callback to invoke when text of editor changes.
   * @param {EditorTextChangeEvent} event - custom event.
   * @group Emits
   */
  @Output() onTextChange: EventEmitter<EditorTextChangeEvent> =
    new EventEmitter<EditorTextChangeEvent>();
  /**
   * Callback to invoke when selection of the text changes.
   * @param {EditorSelectionChangeEvent} event - custom event.
   * @group Emits
   */
  @Output() onSelectionChange: EventEmitter<EditorSelectionChangeEvent> =
    new EventEmitter<EditorSelectionChangeEvent>();
}
