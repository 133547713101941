import { HTTP_INTERCEPTORS, provideHttpClient } from '@angular/common/http';
import { ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { AppStateModule } from '@pinnakl/app-state';
import {
  AuthService,
  DEFAULTSCREEN,
  HttpConfigInterceptor,
  USERTYPE
} from '@pinnakl/auth/providers';
import { EnvironmentModule } from '@pinnakl/core/environment';
import { UploadListenerInterceptor } from '@pinnakl/core/web-services';
import {
  AG_GRID_KEY,
  AG_GRID_KEY_TOKEN,
  HTTP_SERVER_URL,
  HTTP_SERVICE_URL,
  LOCATION
} from '@pinnakl/shared/constants';
import { UserTypes } from '@pinnakl/shared/types';
import { UiToastMessageService } from '@pinnakl/shared/ui/prime';
import { PageSubscriptionsHandler, getAppInstanceToken } from '@pinnakl/shared/util-helpers';
import {
  InlineSVGModule,
  PinnaklErrorHandler,
  PinnaklUIToastMessage
} from '@pinnakl/shared/util-providers';
import { AuthModule } from 'angular-auth-oidc-client';
import { MessageService } from 'primeng/api';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';

const authConfig = {
  ...environment.authConfig,
  configId: getAppInstanceToken(
    environment.authConfig?.configId,
    location?.host,
    environment.appName
  )
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(),
    provideRouter(appRoutes),
    importProvidersFrom(BrowserAnimationsModule),
    importProvidersFrom(EnvironmentModule.forRoot({ config: environment })),
    // todo: remove when new settings page used
    importProvidersFrom(AppStateModule),
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UploadListenerInterceptor,
      multi: true
    },
    {
      provide: HTTP_SERVICE_URL,
      useValue: environment.httpServerUrl
    },
    { provide: DEFAULTSCREEN, useValue: { prod: 'dataroom', dev: 'dataroom' } },
    { provide: USERTYPE, useValue: UserTypes.EXTERNAL },
    {
      provide: PinnaklUIToastMessage,
      useClass: UiToastMessageService
    },
    MessageService,
    importProvidersFrom(AuthModule.forRoot({ config: authConfig })),
    importProvidersFrom(InlineSVGModule.forRoot({ baseUrl: '/assets/svg/' })),
    {
      provide: HTTP_SERVER_URL,
      useValue: environment.httpServerUrl
    },
    {
      provide: LOCATION,
      useValue: location
    },
    {
      provide: ErrorHandler,
      useClass: PinnaklErrorHandler
    },
    {
      provide: AG_GRID_KEY_TOKEN,
      useValue: AG_GRID_KEY
    },
    PageSubscriptionsHandler
  ]
};
