import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  OnInit,
  signal
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import { AuthService } from '@pinnakl/auth/providers';
import { PortalUserStore } from '@pinnakl/portal-v2/data-access';
import { PortalClient, PortalUserUpdatePayload } from '@pinnakl/portal-v2/domain';
import { LocalSpinnerSimpleModeContainerComponent } from '@pinnakl/shared/base-components';
import { getErrorMessage } from '@pinnakl/shared/util-helpers';
import { PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { DialogService } from 'primeng/dynamicdialog';
import { InvestorPortalHeaderComponent } from '../investor-portal-header/investor-portal-header.component';
import {
  DisclaimerModalComponent,
  DisclaimerModalData,
  ProfileRegistrationModalComponent,
  ProfileRegistrationModalDataResult
} from '../modals';

@Component({
  selector: 'investor-portal-app-container',
  standalone: true,
  imports: [RouterOutlet, InvestorPortalHeaderComponent, LocalSpinnerSimpleModeContainerComponent],
  templateUrl: './investor-portal-app-container.component.html',
  styleUrl: './investor-portal-app-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvestorPortalAppContainerComponent implements OnInit {
  private readonly toast = inject(PinnaklUIToastMessage);
  private readonly destroyRef = inject(DestroyRef);
  private readonly authService = inject(AuthService);
  private readonly dialogService = inject(DialogService);
  private readonly portalUserStore = inject(PortalUserStore);
  protected readonly showRoutes = signal(false);
  protected readonly portalUser = this.portalUserStore.portalUser;
  protected readonly selectedClient = this.portalUserStore.selectedClient;
  protected readonly loadingPortalUser = this.portalUserStore.portalUserLoading;
  protected readonly loadedPortalUser = this.portalUserStore.portalUserLoaded;

  async ngOnInit(): Promise<void> {
    try {
      const portalUser = await this.portalUserStore.loadPortalUser();
      const selectedClient = this.portalUserStore.selectedClient();
      if (selectedClient) {
        if (portalUser?.passwordResetRequired) {
          this.openProfileRegistrationModal(selectedClient);
        } else if (!selectedClient.disclaimerSigned && selectedClient.enableDisclaimer) {
          this.openDisclaimerModal(false);
        } else {
          this.showRoutes.set(true);
        }
      }
    } catch (error) {
      this.authService.logout();
    }
  }

  private openProfileRegistrationModal(
    selectedClient: PortalClient,
    profileDetails?: ProfileRegistrationModalDataResult
  ): void {
    selectedClient &&
      this.dialogService
        .open(ProfileRegistrationModalComponent, {
          dismissableMask: false,
          maskStyleClass: 'bg-black-alpha-50',
          header: 'Profile Registration',
          width: '600px',
          closeOnEscape: false,
          showHeader: true,
          closable: false,
          data: {
            profileDetails,
            client: selectedClient,
            user: this.portalUserStore.portalUser()
          }
        })
        .onClose.pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(async (closeResult: ProfileRegistrationModalDataResult): Promise<void> => {
          if (closeResult) {
            if (selectedClient.enableDisclaimer && !selectedClient.disclaimerSigned) {
              this.openDisclaimerModal(true, closeResult);
            } else {
              await this.savePortalUserProfile(closeResult);
              this.showRoutes.set(true);
            }
          } else {
            this.authService.logout();
          }
        });
  }

  private openDisclaimerModal(
    showBackButton: boolean,
    profileDetails?: ProfileRegistrationModalDataResult
  ): void {
    const selectedClient = this.portalUserStore.selectedClient();
    if (selectedClient) {
      const disclaimerData: DisclaimerModalData = {
        showBackButton,
        logoUrl: selectedClient.logoFileUrl,
        clientName: selectedClient.clientName,
        disclaimer: selectedClient.disclaimerMessage
      };
      this.dialogService
        .open(DisclaimerModalComponent, {
          dismissableMask: false,
          maskStyleClass: 'bg-black-alpha-50',
          header: ``,
          width: '600px',
          closeOnEscape: false,
          showHeader: false,
          closable: false,
          data: disclaimerData
        })
        .onClose.subscribe(async closeResult => {
          if (closeResult) {
            try {
              if (showBackButton && profileDetails) {
                await this.savePortalUserProfile(profileDetails);
                this.toast.success('Portal profile saved successfully.');
              }
              await this.signDisclaimer();
              this.toast.success('Disclaimer has been signed.');
            } catch (error) {
              this.toast.error('Unable to save portal user profile. ' + getErrorMessage('', error));
            }
            this.showRoutes.set(true);
          } else {
            const selectedClient = this.portalUserStore.selectedClient();
            selectedClient && this.openProfileRegistrationModal(selectedClient, profileDetails);
          }
        });
    }
  }

  private async savePortalUserProfile(portalUserProfile: {
    firstName: string;
    lastName: string;
    phone: string;
    otp: 'email' | 'phone';
    newPassword: string;
  }): Promise<void> {
    const updatedProfile = {
      firstName: portalUserProfile.firstName,
      lastName: portalUserProfile.lastName,
      phoneNumber: portalUserProfile.phone,
      authType: 'TwoFactor',
      otpChannel: portalUserProfile.otp === 'email' ? 'Email' : 'Mobile',
      password: portalUserProfile.newPassword,
      passwordResetRequired: false
    };
    await this.portalUserStore.updatePortalUser(updatedProfile as PortalUserUpdatePayload);
    this.authService.passwordResetDone();
  }

  private async signDisclaimer(): Promise<void> {
    const portalUser = this.portalUserStore.portalUser();
    if (portalUser) {
      await this.portalUserStore.updatePortalUserClient({
        disclaimerSigned: true
      });
    }
  }
}
